import React, { useCallback, useEffect, useState } from 'react';
import { Badge, Button, Modal, Table } from 'react-bootstrap';
import useBreakpoint from '@hooks/useBreakpoint';
import clsx from 'clsx';
import moment from 'moment';
import { LinkData } from 'types/dashboard.type';
import axiosInstance from '@utils/axios-service';
import UserJson from 'types/user.type';
import useAuth from '@hooks/useAuth';

type LinksProps = {
  profile?: UserJson.Profile;
  show: boolean;
  onHide: () => void;
};

const LeakedLinksModal = (props: LinksProps) => {
  const { user } = useAuth();
  const { isMobile } = useBreakpoint();
  const [allLinks, setAllLinks] = useState<LinkData[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(0);

  const itemsPerPage = 6;
  const startIndex: number = (currentPage - 1) * itemsPerPage + 1;
  const endIndex: number = Math.min(startIndex + itemsPerPage - 1, totalItems);

  const fetchAllLinks = useCallback(async () => {
    if (!user) return;
    let url = `/api/profile/links/list?page=${currentPage}&page_size=${itemsPerPage}&profile_id=${props.profile?.id}&account_id=${user?.id}`;
    if (!props.profile)
      url = `/api/profile/links/list?page=${currentPage}&page_size=${itemsPerPage}&account_id=${user?.id}&profile_id=0`;
    try {
      const res = await axiosInstance.get(url);
      setAllLinks(res.data.data.leaked_links);
      setTotalItems(res.data.data.total);
    } catch (error) {}
  }, [currentPage, itemsPerPage, props.profile, user]);

  useEffect(() => {
    fetchAllLinks();
  }, [fetchAllLinks]);

  const handlePreviousClick = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextClick = () => {
    const total: number = Math.ceil(totalItems / itemsPerPage);
    if (currentPage < total) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="border-0">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="fs-5 fw-semibold "
          style={{ wordSpacing: '0.03px' }}
        >
          All of your links
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="scrollable-container">
        <div className="d-flex justify-content-between align-items-center border-0 mb-2">
          <div>
            <h6>{`Showing ${startIndex}-${endIndex} of ${totalItems}`}</h6>
          </div>
          <div className="d-flex gap-3">
            <Button
              size="sm"
              className="bg-secondary text-black fw-regular border-0 py-1"
              onClick={handlePreviousClick}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <Button
              size="sm"
              className="bg-secondary text-black fw-regular border-0 py-1"
              onClick={handleNextClick}
              disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}
            >
              Next
            </Button>
          </div>
        </div>
        <Table variant="white" responsive className="scrollable-container">
          <thead>
            <tr className="">
              <th
                className={clsx(
                  'text-middle-muted fw-semibold border-0',
                  isMobile && 'fs-sm',
                )}
              >
                Date
              </th>
              <th
                className={clsx(
                  'text-middle-muted fw-semibold border-0',
                  isMobile && 'fs-sm',
                )}
              >
                Website
              </th>
              <th
                className={clsx(
                  'text-middle-muted fw-semibold border-0',
                  isMobile && 'fs-sm',
                )}
              >
                Source Type
              </th>
              <th
                className={clsx(
                  'text-middle-muted fw-semibold border-0 text-left',
                  isMobile && 'fs-sm',
                )}
              >
                Status
              </th>
            </tr>
          </thead>
          <tbody className="link-list ">
            {allLinks.map((link, id) => (
              <tr key={id}>
                <td valign="middle" className={clsx(isMobile && 'fs-sm')}>
                  {moment(link.Date).format('MM/DD/YYYY')}
                </td>
                <td valign="middle" className={clsx(isMobile && 'fs-sm')}>
                  {link.source_link}
                </td>
                <td valign="middle" className={clsx(isMobile && 'fs-sm')}>
                  {link.source_type}
                </td>
                <td className={clsx('py-4', isMobile && 'fs-sm')} align="left">
                  <Badge
                    bg={`${link.status === 'Denied' ? 'danger' : 'teal'} fs-sm fw-bold d-flex align-items-center`}
                    style={{ width: 'fit-content', height: 28 }}
                    className={clsx(isMobile && 'fs-sm')}
                  >
                    {link.status}
                  </Badge>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between border-0">
        <div>
          <h6>{`Showing ${startIndex}-${endIndex} of ${totalItems}`}</h6>
        </div>
        <div className="d-flex gap-3">
          <Button
            size="sm"
            className="bg-secondary text-black fw-regular border-0 py-1"
            onClick={handlePreviousClick}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <Button
            size="sm"
            className="bg-secondary text-black fw-regular border-0 py-1"
            onClick={handleNextClick}
            disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}
          >
            Next
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default LeakedLinksModal;
