import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Badge,
  Stack,
  Table,
  Button,
  Card,
  Spinner,
  CardBody,
  Alert,
} from 'react-bootstrap';
import DashboardCard from '@components/DashboardCard';
import DoughnutChart from '@components/DoughnutChart';
import SiteCard from '@components/SiteCard';
import UpTriangleIcon from '@assets/icons/UpTriangleIcon';
import Graph1Icon from '@assets/icons/Graph1Icon';
import Graph2Icon from '@assets/icons/Graph2Icon';
import moment from 'moment';
import useBreakpoint from '@hooks/useBreakpoint';
import clsx from 'clsx';
import CongratulationsModal from '@components/modals/CongratulationsModal';
import { Range } from 'react-date-range';
import axiosInstance from '@utils/axios-service';
import { DashboardType } from 'types/dashboard.type';
import LeakedLinksModal from '@components/modals/LeakedLinksModal';
import useAuth from '@hooks/useAuth';
import UserJson from 'types/user.type';
import DashboardFilter from '@components/DashboardFilter';

const Dashboard: React.FC = () => {
  const { profiles, paymentDetails } = useAuth();
  const [selectedProfile, setSelectedProfile] = useState<
    UserJson.Profile | undefined
  >(profiles[0]);
  const [selectedRange, setSelectedRange] = useState<Range>({
    startDate: moment().subtract(7, 'days').toDate(),
    endDate: new Date(),
    key: 'range',
  });
  const [loading, setLoading] = useState(true);
  const { isMobile } = useBreakpoint();
  const [data, setData] = useState<DashboardType | null>(null);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    const fetchDashboardData = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get<{ data: DashboardType }>(
          `/api/profile/dashboard?start_date=${moment(selectedRange.startDate).format('MM/DD/YY')}&end_date=${moment(selectedRange.endDate).format('MM/DD/YY')}${selectedProfile?.id ? `&profile_id=${selectedProfile?.id}` : ''}`,
        );
        setData(response.data.data);
      } catch (error) {}
      setLoading(false);
    };

    fetchDashboardData();
  }, [selectedRange, selectedProfile]);

  const graph_options: number[] = [
    data?.removals?.delisted_from_google,
    data?.removals?.delisted_from_source,
    data?.removals?.submitted_links,
  ].filter((value): value is number => value !== undefined);
  const removal = graph_options.reduce(
    (acc, currentValue) => acc + currentValue,
    0,
  );
  const totalRemoval = data?.removals.total;
  let percentageTotalRemoval = 0;
  if (!!totalRemoval) {
    percentageTotalRemoval = parseFloat((removal / totalRemoval).toFixed(2));
  }
  if (
    paymentDetails?.can_access_dashboard === false ||
    paymentDetails?.current_payment_status === 'pending'
  ) {
    return (
      <Alert>
        You don't have permission to access this page. Please update billing
        information in Billing page
      </Alert>
    );
  }
  return (
    <div>
      <h1 className="fs-xxl mb-3 mb-md-4">Overview</h1>
      <DashboardFilter
        selectedRange={selectedRange}
        setSelectedRange={setSelectedRange}
        profile={selectedProfile}
        setProfile={setSelectedProfile}
      />
      {loading && (
        <div className="d-flex p-5 justify-content-center">
          <Spinner />
        </div>
      )}
      {!loading && (
        <>
          <Row>
            <Col xs={6} sm={6} md={4}>
              {data && data.total_views && (
                <DashboardCard
                  title="Total Views"
                  value={data?.total_views?.value}
                  percentage={data?.total_views?.percentage}
                  since={data?.total_views?.since}
                  up={data?.total_views?.up}
                  backgroundColor="#e6fdec"
                />
              )}
            </Col>
            <Col xs={6} sm={6} md={4}>
              {data && (
                <DashboardCard
                  title="Total Media Found"
                  value={data?.total_media?.value}
                  percentage={data?.total_media?.percentage}
                  since={data?.total_media?.since}
                  up={data?.total_media?.up}
                  backgroundColor="#f8fbe9"
                />
              )}
            </Col>
            <Col xs={6} sm={6} md={4} className="d-block d-md-none">
              {data && (
                <DashboardCard
                  title="Total Views"
                  value={data?.total_views?.value}
                  percentage={data?.total_views?.percentage}
                  since={data?.total_views?.since}
                  up={data?.total_views?.up}
                  backgroundColor="#f9d1d8"
                />
              )}
            </Col>
            <Col xs={6} sm={6} md={4}>
              {data && (
                <DashboardCard
                  title="Estimated Value"
                  value={data?.expected_value?.value}
                  percentage={data?.expected_value?.percentage}
                  since={data?.expected_value?.since}
                  up={data?.expected_value?.up}
                  backgroundColor="#ecf4fe"
                />
              )}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs={12} sm={12} md={6}>
              <Card className="my-4 shadow-sm bg-white p-4 rounded-3 border-slight">
                <div className="d-flex justify-content-center align-items-center gap-2">
                  <div
                    className="d-flex justify-content-center align-items-center position-relative"
                    style={{ height: 220, minWidth: 170 }}
                  >
                    {graph_options.some((item) => item !== 0) ? (
                      <DoughnutChart data={graph_options} />
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          maxWidth: 150,
                          maxHeight: 150,
                          height: '100%',
                          borderRadius: 99999,
                          borderColor: '#AAAAAA',
                          borderWidth: 30,
                          borderStyle: 'solid',
                        }}
                      ></div>
                    )}
                    <div className="position-absolute d-flex flex-column align-items-center">
                      <span className="fs-sm fw-bold">
                        <img src="/logo.png" width={70} alt="" />
                      </span>
                      <span className="fs-xxs fw-bold text-middle-muted">
                        average range
                      </span>
                    </div>
                  </div>
                  <div>
                    <p
                      className={clsx(
                        'fw-semibold',
                        isMobile ? 'fs-md' : 'fs-4',
                      )}
                    >
                      Total Removals
                    </p>
                    <Stack direction="horizontal" gap={2} className="flex-wrap">
                      <div>
                        <p className="fw-semibold fs-4">
                          {data?.removals?.total}
                        </p>
                      </div>
                      <Badge
                        bg="danger"
                        className="d-flex align-items-end gap-1"
                      >
                        <UpTriangleIcon /> {percentageTotalRemoval} %
                      </Badge>
                    </Stack>
                    <p className="fs-xs text-middle-muted mt-1">
                      Compared to last month
                    </p>
                  </div>
                </div>
                <hr className="mt-0" />
                <div className="d-flex flex-column gap-3">
                  <Stack
                    direction="horizontal"
                    className="justify-content-between align-items-center"
                    gap={1}
                  >
                    <div className="d-flex align-items-center gap-3">
                      <div
                        className="bg-danger rounded-1"
                        style={{ width: 14, height: 14, minWidth: 14 }}
                      />
                      <span
                        className={clsx(
                          'fw-semibold',
                          isMobile ? 'fs-xs' : 'fs-lg',
                        )}
                      >
                        Delisted from Google
                      </span>
                    </div>
                    <div className="w-20 ms-auto text-center fs-sm text-middle-muted">
                      {data?.removals.delisted_from_google}
                    </div>
                    <Graph1Icon
                      width={isMobile ? 50 : 101}
                      height={isMobile ? 14 : 28}
                      value={data?.removals.delisted_from_google}
                    />
                  </Stack>
                  <Stack
                    direction="horizontal"
                    className="justify-content-between align-items-center"
                    gap={1}
                  >
                    <div className="d-flex align-items-center gap-3">
                      <div
                        className="bg-primary rounded-1"
                        style={{ width: 14, height: 14, minWidth: 14 }}
                      />
                      <span
                        className={clsx(
                          'fw-semibold',
                          isMobile ? 'fs-xs' : 'fs-lg',
                        )}
                      >
                        Removed from Source
                      </span>
                    </div>
                    <div className="w-20 ms-auto text-center fs-sm text-middle-muted">
                      {data?.removals.delisted_from_source}
                    </div>

                    <Graph2Icon
                      width={isMobile ? 50 : 101}
                      height={isMobile ? 14 : 28}
                      value={data?.removals.delisted_from_source}
                    />
                  </Stack>
                  <Stack
                    direction="horizontal"
                    className="justify-content-between align-items-center"
                    gap={1}
                  >
                    <div className="w-50 d-flex align-items-center gap-3">
                      <div
                        className="bg-teal rounded-1"
                        style={{ width: 14, height: 14, minWidth: 14 }}
                      />
                      <span
                        className={clsx(
                          'fw-semibold',
                          isMobile ? 'fs-xs' : 'fs-lg',
                        )}
                      >
                        Submitted links
                      </span>
                    </div>
                    <div className="w-20 ms-auto text-center fs-sm text-middle-muted">
                      {data?.removals.submitted_links}
                    </div>
                    <Graph1Icon
                      color="#b9f9c8"
                      width={isMobile ? 50 : 101}
                      height={isMobile ? 14 : 28}
                      value={data?.removals.submitted_links}
                    />
                  </Stack>
                </div>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <div className="ms-0 ms-md-5">
                <p className="fw-semibold fs-3 mb-4 mt-2 mt-md-5">
                  Top 3 sites
                </p>
                <Stack direction="vertical" className="" gap={isMobile ? 2 : 4}>
                  {data?.top_sites.map((item, index) => (
                    <SiteCard
                      key={index}
                      id={index + 1}
                      siteUrl={item.domain}
                      link={item.links}
                    />
                  ))}
                </Stack>
              </div>
            </Col>
          </Row>
          <Card className="rounded-3 border-slight shadow-sm">
            <CardBody>
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h2 className="m-0 fs-4 fw-semibold">All of your links</h2>
                <Button
                  variant="link"
                  className="text-decoration-none"
                  onClick={() => setModalShow(true)}
                >
                  View all
                </Button>
              </div>
              {!isMobile && (
                <div className="table-responsive">
                  <Table responsive>
                    <thead>
                      <tr className="">
                        <th
                          className={clsx(
                            'text-middle-muted fw-semibold border-0',
                            isMobile && 'fs-sm',
                          )}
                        >
                          Date
                        </th>
                        <th
                          className={clsx(
                            'text-middle-muted fw-semibold border-0',
                            isMobile && 'fs-sm',
                          )}
                        >
                          Website
                        </th>
                        <th
                          className={clsx(
                            'text-middle-muted fw-semibold border-0',
                            isMobile && 'fs-sm',
                          )}
                        >
                          Source Type
                        </th>
                        <th
                          className={clsx(
                            'text-middle-muted fw-semibold border-0 text-center',
                            isMobile && 'fs-sm',
                          )}
                        >
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.leaked_links.map((link, id) => (
                        <tr key={id}>
                          <td
                            valign="middle"
                            className={clsx(isMobile && 'fs-sm')}
                          >
                            {moment(link.Date).format('MM/DD/YYYY')}
                          </td>
                          <td
                            valign="middle"
                            className={clsx(isMobile && 'fs-sm')}
                          >
                            {link.source_link}
                          </td>
                          <td
                            valign="middle"
                            className={clsx(isMobile && 'fs-sm')}
                          >
                            {link.source_type}
                          </td>
                          <td
                            className={clsx('py-4', isMobile && 'fs-sm')}
                            align="center"
                          >
                            <Badge
                              bg="teal fs-sm fw-bold d-flex align-items-center"
                              style={{ width: 'fit-content', height: 28 }}
                              className={clsx(isMobile && 'fs-sm')}
                            >
                              {link.status}
                            </Badge>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              )}
              {isMobile && (
                <div>
                  {data?.leaked_links.map((link, id) => (
                    <Card key={id} className="p-3 mb-2">
                      <p className={'fs-sm'}>
                        <span className="fw-medium">Date:</span>{' '}
                        {moment(link.Date).format('MM/DD/YYYY')}
                      </p>
                      <p className={'fs-sm'}>
                        <span className="fw-medium">Website:</span>{' '}
                        {link.source_link}
                      </p>
                      <p className={'fs-sm mb-2'}>
                        <span className="fw-medium">Source Type:</span>{' '}
                        {link.source_type}
                      </p>

                      <Badge
                        bg="teal fs-sm fw-bold d-flex align-items-center"
                        style={{ width: 'fit-content', height: 28 }}
                        className={clsx(isMobile && 'fs-sm')}
                      >
                        {link.status}
                      </Badge>
                    </Card>
                  ))}
                </div>
              )}
            </CardBody>
          </Card>
        </>
      )}

      <CongratulationsModal />
      <LeakedLinksModal
        profile={selectedProfile}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
};

export default Dashboard;
